import dayjs from 'dayjs'
import {
  Chart,
  BarElement,
  PointElement,
  BarController,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  LinearScale,
  CategoryScale
} from 'chart.js';

Chart.register(
  BarElement,
  PointElement,
  BarController,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  LinearScale,
  CategoryScale
);

function parseLabels(data) {
  var localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat)
  let labels = Object.keys(data).map((key) => {
    return dayjs.unix(key).format('LLL')
  })
  return labels
}

function parseScores(data) {
  return Object.values(data)
}

function showChart(data){
  var ctx = document.getElementById('myChart').getContext('2d');
    
    var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: parseLabels(data.all),
          datasets: [{
              label: 'ASQ score ™',
              data: parseScores(data.all),
              backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
              ],
              borderColor: [
                'rgba(54, 162, 235, 1)',
              ],
              borderWidth: 1
          }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 100
            }
          }
        }
    });  
}

const fetchScores = async () => {
  const response = await fetch('/api/session_scores', {
      "method": "GET"
  });
  const data = await response.json();
  showChart(data)
}

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('myChart')) {
    fetchScores()
  }
})